import { ref } from '@vue/composition-api'
import contentHelpRepository from '@/repository/contentHelpRepository'
import store from '@/store'

export default function contentHelpService() {
  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const getElement = id => new Promise(resolve => {
    contentHelpRepository.getElement(id)
      .then(res => {
        if (!res) {
          throw new Error('Статьи с текущем идентификатором не найдено')
        }
        resolve(res)
      })
      .catch(error => {
        store.commit('app/SET_ERROR', error)
        resolve(null)
      })
  })

  const getList = () => new Promise(resolve => {
    contentHelpRepository.elementList({
      query: searchQuery.value,
      page: currentPage.value,
      limit: perPage.value,
      sord: isSortDirDesc.value ? 'desc' : 'asc',
      sidx: sortBy.value,
    })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        store.commit('app/SET_ERROR', error)
        resolve(null)
      })
  })

  return {
    perPage, totalItems, currentPage, perPageOptions, searchQuery, sortBy, isSortDirDesc, getElement, getList,
  }
}
